import { useEffect, useState } from "react";
import { connect } from "react-redux";
import TextView from "../partials/Typography";
import DropDown from "../partials/DropDown";
import RoleSelection from "./partials/RoleSelection";
import Avatar from "./partials/Avatar";
import AvatarDropDown from "../partials/user_profile/AvatarDropDown";
import { isEmpty } from "../../helpers/Functions";
import { setCurrentCapabilities } from "../../app/redux/slices/authSlice";
import { getCompanyCapabilities } from "../../helpers/Utils";
import { ROLE_ADMIN, ROLE_MENTOR } from "../../models/Constants";
import { useDispatch } from "react-redux";

const MainNavbar = ({
	update = false,
	setUpdate = () => {},
	userDetails,
	userCapabilities,
	currentCapabilities,
	setCurrentCapabilities,
}) => {
	const [selectedCompany, setSelectedCompany] = useState(currentCapabilities?.company?.company_id);
	const [companyOptions, setCompanyOptions] = useState([]);
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const dispatch = useDispatch();

	const toggleDropdown = () => {
		setIsDropdownOpen((prev) => !prev);
	}

	const handleOutsideClick = (event) => {
		if (event.target.closest(".theme-header-profile") === null) {
			setIsDropdownOpen(false);
		}
	};

	useEffect(() => {
		document.addEventListener("click", handleOutsideClick);

		return () => {
			document.removeEventListener("click", handleOutsideClick);
		}
	}, []);

	useEffect(() => {
		let companyData = [];

		if (!isEmpty(userCapabilities)) {
			userCapabilities.map((company) => {
				companyData.push({
					key: company.company_id,
					name: company.company_name
				});
			});
		}

		setCompanyOptions(companyData);
	}, [userCapabilities]);

	const handleSelectCompany = (e) => {
		let selected = getCompanyCapabilities(userCapabilities, e.target.value);
		let updatedCapabilities = { ...currentCapabilities };
		updatedCapabilities.company = selected;

		// Use the Mentor or Admin role if the user qualifies for either.
		let roleUpdate = selected.roles.find((role) =>
			((role.role_standard_id === ROLE_ADMIN || role.role_id === ROLE_ADMIN)
			|| (role.role_standard_id === ROLE_MENTOR || role.role_id === ROLE_MENTOR))
		);

		// If the user is not admin or mentor select the first role
		if(isEmpty(roleUpdate)) {
			roleUpdate = selected.roles[0];
		}

		updatedCapabilities.role = roleUpdate.role_id;
		updatedCapabilities.role_name = roleUpdate.role_name;
		updatedCapabilities.is_limited = roleUpdate.is_limited;

		setSelectedCompany(e.target.value);
		dispatch(setCurrentCapabilities(updatedCapabilities));

		window.location.href = '/dashboard';
	}

	return (
		<div className="theme-header">
			<div className="theme-header-breadcrumbs">
				<TextView customclasses="theme-header-breadcrumbs-left-text">
					Organization:
				</TextView>
				<DropDown
					itemclass="theme-header-breadcrumbs-selection"
					value={selectedCompany}
					options={companyOptions}
					onChange={handleSelectCompany}
					small="true"
				/>
			</div>
			<div className="theme-header-profile">
				{/* top right */}
				<RoleSelection />
				{
					userDetails && (
						<div className="theme-header-profile-container" onClick={toggleDropdown} >
							<div className="theme-header-profile-name font-weight-medium">
								{userDetails.firstname ? `Hi ${userDetails.firstname}!` : ""}
							</div>
							<Avatar
								userdetail={userDetails}
								setUpdate={setUpdate}
							/>
							{isDropdownOpen && (
								<>
									<AvatarDropDown
										userdetail={userDetails}
										setUpdate={setUpdate}
									/>
								</>
							)}
						</div>
					)
				}
			</div>
		</div>
	);
}


const mapStateToProps = (state) => {
	let data = {
		userDetails: state.auth.user_details,
		userCapabilities: state.auth.user_capabilities,
		currentCapabilities: state.auth.current_capabilities,
	}

	return data;
}

const mapDispatchToProps = {
	setCurrentCapabilities
}

export default connect(mapStateToProps, mapDispatchToProps)(MainNavbar);